form {
    textarea {
        border-radius: 15px;
    }
}
.dataTables_wrapper {
    .dataTables_length {
        select {
            border-radius: 6px;
            padding-left:0.5rem;
            padding-right: 1.5rem;
            text-align: center;
        }
    }
    .dataTables_paginate {
        .pagination {
            li {
                min-width:35px;
                text-align: center;
                a {
                    min-width:35px;
                }
            }
        }
    }
    table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control, 
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
        padding-left:2rem;
        &:before {
            background-color: $blue;
            top: calc(50% - 2px);
        }
    }
    tr.child {
        td.child {
            .dtr-details {
                li {
                    display: flex;
                    .dtr-title {
                        min-width: 150px;
                    }
                    .dtr-data {
                        img {
                            max-width: 100px;
                        }
                    }
                }
            }
        }
    }
    div.dt-button-collection div.dropdown-pane {
        width: 100%;
    }    
}
table {
    th {
        font-size:$global-font-size;
    }
}
body.subpage {
    table {
        th {
            font-size:$global-font-size;
        }
        td {
            ul {
                li {
                    font-size:$global-font-size;
                }
            }
            p {
                font-size:$global-font-size;
            }
        }
    }
}