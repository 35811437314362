.page-container {
  max-width: 1048px;
  margin-left: auto;
  margin-right: auto;

  .grid-container {
    padding: 0;

    .small-padding {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.component-container {
  padding: $section_padding 0px;

  .grid-container {
    &.component {
      .inner-component {
        margin-bottom: $section_padding;
      }
    }

    &.shadow {
      box-shadow: 0 9px 27px -1px rgba(0, 0, 0, 0.10);
    }
  }

  &.light {
    +.light {
      padding-top: 0px;
    }
  }

  &.component-container-padding {
    .grid-container {
      padding: 5rem 0px;
    }
  }

  &.masonry-gallery-container {
    padding: 0px;
  }

  &.home-body-container {
    width: 90%;
    margin: 1rem auto;
    padding: 0;

    @media (min-width: 1024.05px) {
      width: 60%;
      margin: 69px auto 88.5px auto;
    }

    @media (max-width: 1024px) {
      margin-top: 4rem;
    }

    .grid-container {
      padding: 0;

      p {
        @media (min-width: 1024.05px) {
          width: 80%;
          margin: auto;
        }
      }
    }
  }
}

.data-maps-container {
  iframe {
    width: 100%;
  }
}