footer {
    color: $black;
    h2, h3, h4, h5 {
        color: $black;
    }

    .footer-top {
        background: url("/files/images/illustrated_map.png");
        background-color: #fff;
        padding: 45px 0px 35px 0px;
        background-position: center;
        margin: 20px 0px 0px 0px;
        -webkit-box-shadow: 0 4px 4px -2px #bdbdbd;
        -moz-box-shadow: 0 4px 4px -2px #BDBDBD;
        box-shadow: 0 4px 4px -2px #bdbdbd;
        min-height: 105px;
    }

    .footer-middle-container {
        background-color: #EFEFEF;
        padding: 97px 0 43.5px 0;

        @include breakpoint(medium down) {
            padding: 118px 0 60px 0;
        }

        .grid-container {
            max-width: 1048px;
            margin: 0 auto;
            padding: 0;
        }

        .about-spf, .footer-nav-col {
            list-style-type: none;
        }

        .about-spf {
            text-align: left;
            margin-right: 35px;
            width: 40%;

            @media (max-width: 1010px) {
                width: 22%;
                margin-right: 20px;
            }

            @include breakpoint(small only) {
                text-align: center;
                margin-right: 0;
                width: 100%;
            }
    
            .about-spf-title {
                font-family: 'Lato';
                font-size: 13px;
                color: #000000;
                margin-bottom: 1.25rem;
                letter-spacing: 0.7px;
                text-transform: uppercase;
            }

            span:last-child {
                p {
                    margin-bottom: 0;
                }
            }
        }

        .footer-nav-col {
            width: 18%;

            &:last-child {
                margin-right: 0;
            }

            @include breakpoint(medium only) {
                width: 22%;
                margin-right: 20px;
            }

            @include breakpoint(small only) {
                margin: 20px 0;
                text-align: center;
                width: 100%;
            }
        }

        p {
            font-family: 'Lato';
            font-size: 1.0625rem;
            line-height: 1.5625rem;
            margin-bottom: 22px;
        }

        h2.pane-title {
            letter-spacing: 0.02em;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
            color: black;
            font-family: "Lato",sans-serif;
            margin-top: 0;
            line-height: 1.35 !important;
            text-transform: uppercase;
        }

        ul.menu {            
            li a {
                text-decoration: none;
                font-family: 'Lato';
                font-size: 0.875rem !important;
                color: #5D5D5D;
                line-height: 26px;
                padding: 0;
                margin-left: 1em;

                @include breakpoint(small only) {
                    text-align: center;
                }

                &:hover, &:focus {
                    color: #0f809e;
                }
            }
        }
    }

    .footer-bar {
        background-color: #081C29;

        .grid-container {
            max-width: 1048px;
            margin: 0 auto;
            padding: 0;

            @include breakpoint(medium down) {
                padding-top: 32px;
            }
        }

        @include breakpoint(small only) {
            .small-reverse-col {
                flex-direction: column-reverse;

                .copyright-footer {
                    text-align: center;
                }

                .logo-footer {
                    margin: 0 auto;
                }
            }
        }

        .copyright-footer {
            float: left;
            padding: 31px 0px 30px 0px;
            font-family: Lato;
            font-size: 10px;
            color: #6b869c;
            letter-spacing: 1.91px;
            line-height: 23px;

            p {
                margin-bottom: auto;
                line-height: inherit;
            }
        }

        .logo-footer {
            background-color: white;
            margin-left: auto;

            img {
                width: 100px;
            }
        }

        .svg-icon-footer {
            width: 45px;
            height: 45px;
            background-color: #146FF8;
            border-radius: 50%;
            inset: auto 20px 20px auto;
            position: fixed;

            svg {
                position: absolute;
                max-width: 65% !important;
                max-height: 65% !important;
                overflow: visible !important;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}