.sidebar {
    max-width: 300px;
    margin:0px auto;
    @include breakpoint(medium only) {
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        padding: 0px;   
        .block-card {
            margin-bottom:0px;
            &.card {
                margin-bottom:0px;
            }
        }                     
    }
    @include breakpoint(small only) {
        max-width: 95%;
    }
}