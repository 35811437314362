.ups-grid {
  margin-left: auto;
  margin-right: auto;
  justify-items: center;
  align-items:top;

  &.small-ups-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    gap: 3.75rem 2.75rem;
    padding: 0px;
  }

  &.medium-ups-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
    gap: 2.75rem 1.625rem; 
    padding: 0px;
  }

  &.large-ups-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    gap: 3.75rem 2.75rem;
    padding: 0px;
  }
}
.flex-grid {
  display:flex;
  .flex-2-fixed {
    flex: 0 0 140px;
  }
  .flex-1 {
    flex: 0 0 10%;
  }
  .flex-2 {
    flex: 0 0 20%;
  }
  .flex-3 {
    flex: 0 0 30%;
  }
  .flex-4 {
    flex: 0 0 40%;
  }
  .flex-5 {
    flex: 0 0 50%;
  }
  .flex-6 {
    flex: 0 0 60%;
  }
  .flex-7 {
    flex: 0 0 70%;
  }
  .flex-8 {
    flex: 0 0 80%;
  }   
  .flex-9 {
    flex: 0 0 90%;
  }      
}

.cards-group {
  .card {
    .card-image {
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.cards-row-group {
    .card {
      flex-direction: row;
      margin:2rem auto;

      .card-image {
        flex: 0 0 35%;
      }

      .card-section {
        flex: 0 0 65%;
      }
    }
    &.cards-row-search {
      .card {
        .card-image {
          flex: 0 0 0%;
        }
  
        .card-section {
          flex: 0 0 100%;
        }
      }
    }
}

.cta-container {
  .cta-item {
    display: flex;
    flex-direction: row-reverse;

    &:nth-child(even) {
      flex-direction: row;
    }

    .item-image {
      flex: 0 0 50%;
    }

    .item-description {
      flex: 0 0 50%;
    }
  }

  &.cta-grid {
    .cta-group-wrap {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
      gap: 3.75rem 2.75rem;
      padding: 0px;
    }

    .cta-item {
      flex-direction: row-reverse;

      .item-description {
        display: flex;
        flex-direction: column;
        padding: 2rem;

        .item-header {
          h3 {
            font-family: futura-pt, sans-serif;
            font-size: 1.625rem;
            font-weight: 500;
          }
        }

        .item-copy {
          margin: 0 0 2.1875rem 0;
        }
      }

      .item-image {
        flex: 0 0 45%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.component-intro-content {
  .grid-x {
    padding: 0 2.75rem 3.75rem 2.75rem;
  }
}

.testimonial {
  &-slide {
    padding: 40px 20px;
  }

  &_box {

    &-top {
      background-color: white;
      padding: 30px;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      box-shadow: 5px 5px 20px rgba(152, 193, 217, 0.493);
    }

    &-icon {
      padding: 10px 0;

      i {
        font-size: 25px;
        color: #14213d;
      }
    }

    &-text {
      padding: 10px 0;

      p {
        color: #293241;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    &-img {
      padding: 20px 0 10px;
      display: flex;
      justify-content: center;

      img {
        width: 70px !important;
        height: 70px !important;
        border-radius: 50px;
        border: 2px solid #e5e5e5;
      }
    }

    &-name {
      padding-top: 10px;

      h4 {
        font-size: 20px;
        line-height: 25px;
        color: #293241;
        margin-bottom: 0;
      }
    }

    &-job {
      p {
        color: #293241;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 3px;
        line-height: 20px;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
  }
}