header {
    nav.main-nav {
        ul.menu {
            align-items: center;

            li {
                font-size: 1.2rem;
                font-weight: 700;
                margin: 0px 0.5rem;

                @media (min-width: 1024px) and (max-width: 1169px) {
                    font-size: 1.12rem;
                }

                &.active {
                    > a {
                        background-color: transparent;
                        color: #0f809e;
                    }
                }

                a {
                    color: $dark-blue;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $blue;
                    }
                }

                .fa, .fa-solid {
                    font-size: 1rem;
                    margin-left: 5px;

                    @media (min-width: 1024px) and (max-width: 1169px) {
                        font-size: 0.8rem;
                    }
                }

                > ul.sub-menu {
                    background: $white;
                    border: none;
                    -webkit-box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.4);
                    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.4);

                    > li {
                        margin: 0px;
                        
                        &.hover {
                            a {
                                background-color: black;
                                color: $white;
                            }
                        }

                        a {
                            color: $black;
                            font-weight: normal;
                            padding: 19px 28px;
                            margin: 0;
                            text-decoration: none;
                            text-transform: none;
                        }

                        &.active {
                            > a {
                                background-color: transparent;
                                color: #0f809e;
                            }
                        }
                    }
                }
            }
        }

        @include breakpoint(large) {
            > ul.dropdown.parent-menu {
                > li {
                    > ul.submenu.is-dropdown-submenu {
                        top: 65px;
                    }
                }
            }
        }

        .dropdown.menu.large-horizontal {
            >li.is-dropdown-submenu-parent {
                >a {
                    @media (min-width: 1024px) and (max-width: 1169px) {
                        padding: 1rem 0.25rem;
                    }

                    &::after {
                        display: none;
                        visibility: hidden;
                    }
                }
            }
        }
    }
    nav.mobile-nav {
        ul.menu {
            li {
                &.active {
                    a {
                        background-color: transparent;
                        color: #0f809e;
                    }
                }
            }
        }
    }
}
.breadcrumbs-container{
    nav {
        padding:1rem 0.5rem;
        .breadcrumbs {
            margin:0px; 
            padding:0px;
            li {
                font-weight:600;
            }
        }
    }
}

nav {
    &#side-bar {

        @include breakpoint(large down) {
            padding-left: 10px;
        }
        
        @include breakpoint(small only) {
            margin-top: 68px;
        }

        ul {
            list-style: none;
            margin-left: 0;
            line-height: 1.875rem;
            font-size: 0.9375rem;

            h2.side-bar-title {
                font-family: 'Lato';
                font-size: 1.0625rem;
                font-weight: bold;
                color: #000000;
                letter-spacing: 1.3px;
                text-align: left;
                text-transform: uppercase;
            }

            li {

                &.is-expanded-parent {
                    list-style-image: url("/files/images/sidebar_expanded_arrow.png");
                    list-style-type: circle;
                }

                &.is-collapsed-parent {
                    list-style-image: url("/files/images/sidebar_collapsed_arrow.png");
                    list-style-type: disc;
                }

                &.active > a {
                    background: transparent;
                    font-weight: 800;
                }

                a {
                    color: black;
                    padding: 0;
                    text-decoration: none;
                    line-height: inherit;
    
                    &:hover, &:focus {
                        font-weight: 800;
                        text-decoration: none;
                    }
                }
            } 
        }
    }
}