
h2,
h3,
h5,
h6 {
    font-weight: 800;
}

h4 {
    font-weight: 600;
}

h2,
h3,
h4,
h5,
h6 {
    color: $black;
}
a {
    &:hover {
        text-decoration: underline;
    }

    span {
        color: #356c84;
        text-decoration: underline;
    }
}

input,
select {
    padding-left: 2rem;
}

select {
    padding-right: 40px;
}

.center-text {
    text-align: center;
}

p + h2, p + h3, p + h4, p + h5, p + h6 {
    margin-top: 30px;
}