.search-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        background-image: url('../img/search-icon.svg');
        content: '';
        width: 30px;
        height: 40px;
        display: inline-block;
        background-position: 50% -5px;
        margin-right: 1rem;
    }
}

.arrow-down {
    position: relative;

    &::after {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        background-color: $white;
        position: absolute;
        left: calc(50% - .75rem);
        bottom: -0.75rem;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
}