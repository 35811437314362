.social-menu {
    li {
        font-size: 1.875rem;

        a {
            .fab, .fa-brands {
                color: $white;
            }

            &:hover {
                .fab, .fa-brands {
                    color: $white;
                }
            }
        }
    }
}