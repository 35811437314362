.article-posts {
    .article {
        display: flex;
        flex-direction: row-reverse;
        border-bottom: 1px dashed $medium-gray;
        padding: 1rem 0 2rem 0;

        &:last-child {
            padding-bottom: 0px;
            border-bottom: 2px solid #D8D8D8;
        }

        .article-copy {
            padding: 0 3rem 0 1.5rem;
            min-height: 180px;

            .heading {
                //height: 60px;
                overflow: hidden;

                h3 {
                    font-family: $body-font-family;
                    font-size: 1.25rem;
                    color: $dark-blue;
                    line-height: normal;

                    a {
                        color: $dark-blue;

                        &:hover {
                            text-decoration: underline;
                            color: $blue;
                        }
                    }
                }

                &.event-title {
                    display: flex;
                    flex-direction: column;

                    h3 a {
                        color: #0f809e;
                        font-size: 1rem;
                        font-weight: 600;
                        line-height: 27px;
                    }
                }
            }

            .content-wrap {
                p {
                    line-height: 1.5;
                }
            }
        }

        .article-image {
            flex: 0 0 41%;

            img {
                height: 165px;
                width: 100%;
                object-fit: cover;
            }
        }

        .date {
            display: flex;
            flex-direction: column;
            padding-right: 1.5rem;
            text-align: center;

            @include breakpoint(small only) {
                align-items: flex-start;
            }

            .month {
                font-family: 'Lato';
                font-size: 0.6875rem;
                text-transform: uppercase;
                color: #393939;
                letter-spacing: 2.75px;
            }

            .single-date {
                font-family: 'PT-Serif';
                font-weight: 600;
                font-size: 2.125rem;
                color: #393939;
            }

            .day {
                font-family: 'Lato';
                font-size: 11px;
                color: #393939;
            }
        }

        @include breakpoint(small only) {
            flex-direction: column-reverse;

            .article-image {
                margin-bottom: 1.5rem;

                img {
                    height: 232px;
                }
            }
        }

        @include breakpoint(medium down) {
            &:last-child {
                padding-bottom: 2rem;
                margin-bottom: 3rem;
            }
        }
    }

    &:last-child {
        .article {
            border-bottom: none;
        }
    }

    &.training-events-posts {
        .article {
            display: flex;
            flex-direction: row;
            border-bottom: none;

            .article-copy {
                padding: 0 1.5rem 0 1.5rem
            }

            .training-and-event-location {
                padding-left: 1.5rem;

                @include breakpoint(large) {
                    margin-left: auto;
                }

                @include breakpoint(small only) {
                    margin-top: 1.5rem;
                }
            }
        }

        &.landing {
            border-bottom: 1px solid #D8D8D8;

            .article .article-copy .heading.event-title h3 a {
                color: #1189AB;
                font-weight: normal;
            }
        }
    }
}

.large-12 {
    .article-posts {
        .article-copy {
            flex: 0 0 70%;
        }

        .article-image {
            flex: 0 0 30%;

            img {
                height: 261px;
            }
        }

        @include breakpoint(small only) {
            .article-image {
                img {
                    height: 232px;
                }
            }
        }
    }
}

.cta-articles {
    .article-image {
        border-top: 6px solid $bright-orange;
    }

    .heading {
        text-align: center;
        margin-top: 1.25rem;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-weight: 700;
            margin: 0px;
            padding: 0px;
            color: $dark-blue;

            a {
                font-weight: 800;
                color: $dark-blue;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .content-wrap {
        text-align: center;
        font-size: 1.1875rem;
        font-weight: 600;

        a {
            color: $dark-orange;
        }
    }
}

.updates-training-events-container {

    &.component-container {
        padding-top: 127px;
    }

    .show-news {
        box-shadow: 0 9px 27px -1px rgba(0, 0, 0, 0.1);
        z-index: 100;
        position: relative;
        background-color: white;
        padding-top: 2.5rem;
        max-width: 1119px;
        margin-top: 8rem;

        @include breakpoint(medium down) {
            margin-top: 4rem;
        }

        &.grid-container {
            padding: 0;
        }

        .featured,
        .events {
            .intro-section {
                padding-bottom: 3.4rem;

                @include breakpoint(small only) {
                    margin-bottom: 0.9rem;
                }
            }

            .article-posts {
                padding: 0;

                .article-copy {
                    padding: 0;
                    min-height: 0;
                }
            }

            h2 {
                text-transform: uppercase;
                font-family: 'Lato';
                font-size: 1.0625rem;
                letter-spacing: 1.3px;
            }
        }

        .featured {
            border-top: 20px solid #0f809e;
            padding: 3.1rem 4rem 6.2rem 5.25rem;

            @include breakpoint(small only) {
                padding: 3rem 2rem 1rem 2rem;
            }

            .heading.event-title h3 a {
                text-decoration: underline;
            }

            .content-wrap {
                p {
                    font-size: 1rem;
                    color: #393939;
                    line-height: 1.6875rem;
                    margin-bottom: 6px;
                    font-family: 'Lato';
                }
            }
        }

        .events {
            border-top: 20px solid #8ED1E2;
            padding: 3.1rem 5.25rem 5.6rem 2.5rem;

            @include breakpoint(small only) {
                padding: 3.1rem 2.5em 5.6rem 2.5rem;
            }
        }

        .view-training-events {
            padding-top: 32px;
            padding-right: 2.5rem;
            float: right;
        }
    }
}