.block-card {
    &.card {
        border-radius: 0px;
        border-top: 6px solid $orange;
        margin-bottom:$sidebar_block_spacing;
        .card-divider {
            background-color:transparent;
            h2 {
             font-size: 2rem;
            }
        }
        .card-section {
            color: $extra-blue;
        }
    }
}

.card {
    border-radius: 0px;
    border-top: 6px solid $orange;
    margin-bottom:$sidebar_block_spacing;
    .card-divider {
        background-color:transparent;
    }
    .card-section {
        color: $extra-blue;
    }
}
.cards-container, .cards-cta-container {
    .card {
        &.border-less-card {
            border-left:none;
            border-right:none;
            border-bottom:none;
            img {
                border-radius: 0 0 10px 10px;
            }
        }
    }
}
