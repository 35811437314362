html, body {
    height: 100%;
    letter-spacing: 0.04em;
}

.bg-fast-blue {
    background: #388BA1 !important;
}

h1.page-title {
    line-height: 1.35;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1072px;
    display: block;
    text-align: left;
    font-family: 'PT-Serif';
    font-weight: 600;
    font-size: 3.25rem;
    color: #FFFFFF;

    @include breakpoint(small only) {
        font-size: 28px;
        font-weight: 800;
        color: #fff;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
}

h2.page-title {
    font-family: 'PT-Serif';
    font-weight: 600;
    font-size: 2.375rem;
    color: #000000;
    margin-top: 0.83rem;
    margin-bottom: 0.5rem;
    padding-bottom: 5px;
}

h3 {
    font-family: 'PT-Serif';
    font-weight: 600;
    color: #000000;
}


main {
    a {
        text-decoration: underline;
        word-break: break-word;
    }

    h4 {
        font-weight: 600;
        font-family: 'PT-Serif';
        font-size: 1.5rem
    }

    h5 {
        font-weight: 500;
    }

    .blue-wrap {
        background-color: #0f809e;
        text-align: center;

        &.component-container {
            padding: 0;

            .grid-container {
                padding-top: 38.5px;
                padding-bottom: 38.5px;
            }
        }

        img {
            width: 189px;
            margin-bottom: 24px;
        }

        .small-12.medium-3:last-child img {
            width: 169px;
        }

        p {
            width: 54%;
            font-weight: 600;
            margin: auto;
            letter-spacing: 2px;
            line-height: 18px !important;
            color: #fff;

            @include breakpoint(small only) {
                margin-bottom: 36px;
            }

            a {
                color: #FFFFFF;
                font-family: 'Lato';
                font-size: 0.875rem;
                font-weight: 400;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
            }
        }

        .home-downward-arrow {
            position: absolute;
            left: 50%;
            margin-top: -3px;
            margin-left: -30px;

            img {
                width: auto;
                margin: 0;
            }
        }
    }

    .tag-label {
        background-color: black;
        font-family: 'Lato';
        font-size: 0.8125rem;
        color: #FFFFFF;
        letter-spacing: 0.81px;
        padding: 2px 0.75rem;
        margin-left: auto;
        display: block;
        margin-right: auto;
        width: fit-content;
        margin-bottom: 2rem;
        max-width: 89.25px;
    }

    .thumbnail {
        border: none;
        display: block;
        margin: 7.5px auto 0 auto;
        width: 80%;
    }

    @include breakpoint(large) {
        .lg-margin-right {
            margin-right: 40px;
        }
    }
}

.evidence-based-programs-container {
    h2 {
        margin-bottom: 1.125rem;
        font-family: 'PT-Serif';
        font-weight: 600;
        font-size: 2.375rem;
        color: #000000;
        line-height: 1.35;
    }

    h2 span {
        font-size: 18px;
        font-family: "Lato", sans-serif;
        color: #76a55d;
        font-weight: bold;
        line-height: 2.5;
    }

    h3 {
        font-size: 18px;
        font-family: "Lato", sans-serif;
        color: #76a55d;
        font-weight: bold;
        line-height: 2.5;
    }

    table td {
        vertical-align: middle;
    }



    .field-grouping {
        margin-top: 40px;
    }

    .field-grouping.first {
        margin-top: 0;
    }

    .field-label {
        font-weight: bold;
        font-size: 15px;
        color: #388BA1;
        margin-bottom: 5px;
        line-height: 23px;
    }
}

form {
    #category-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: #F6F6F6;
        color: #393939 !important;
        height: 61px;
        font-family: 'Lato';
        font-weight: bold;
        border: none;

        @include breakpoint(large) {
            width: 164px;
        }

        @include breakpoint(medium down) {
            margin-bottom: 0;
        }
    }

    #edit-title-wrapper {
        position: relative;
        width: auto;
        height: 50px;
        margin-right: 1em;
        border: none;
        border-bottom: 2px solid #979797;
        box-shadow: none;
    }

    input[id^="edit-title-wrapper"]::-webkit-input-placeholder {
        font-family: 'Lato';
        font-size: 1rem;
        color: #757575;
        cursor: pointer;
    }

    input[id^="edit-title-wrapper"]::-moz-placeholder {
        font-family: 'Lato';
        font-size: 1rem;
        color: #757575;
        cursor: pointer;
    }

    input[id^="edit-title-wrapper"]::-ms-placeholder {
        font-family: 'Lato';
        font-size: 1rem;
        color: #757575;
        cursor: pointer;
    }

    input[id^="edit-title-wrapper"]::placeholder {
        font-family: 'Lato';
        font-size: 1rem;
        color: #757575;
        cursor: pointer;
    }

    #year-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: #F6F6F6;
        color: #393939 !important;
        height: 61px;
        font-family: 'Lato';
        font-weight: bold;
        border: none;

        @include breakpoint(large) {
            width: 164px;
        }

        @include breakpoint(medium down) {
            margin-top: 1rem;
        }
    }

    #edit-submit-ll-list {
        background-color: #388BA1;
        padding: 1rem 2.6rem 1rem 1.75rem;
        font-family: 'Lato';
        font-weight: bold;
        font-size: 1rem;
        color: #FFFFFF;
        line-height: 27px;
        text-transform: none !important;
        border-width: 0;
        height: 61px;
        margin-right: 1rem;

        @include breakpoint(medium down) {
            margin: 2.5rem 0;
        }
    }

    &#trainingAndEventsForm {
        @include breakpoint(medium down) {
            margin-bottom: 2rem;
        }

        #category-select {
            @include breakpoint(large) {
                margin-right: 24px;
            }
        }

        #edit-submit-te-list {
            background-color: #388BA1;
            padding: 1rem 3.6rem 1rem 1.75rem;
            font-family: 'Lato';
            font-weight: bold;
            font-size: 1rem;
            color: #FFFFFF;
            line-height: 27px;
            text-transform: none !important;
            border-width: 0;
            height: 61px;
            margin-right: 24px;

            @include breakpoint(medium down) {
                margin: 0.5rem 0 2rem 0;
                width: 100%;
            }
        }
    }
}

a.reset-button {
    height: 61px;
    background-color: #F6F6F6;
    color: #393939;
    font-family: 'Lato';
    font-size: 1rem;
    line-height: 1.6875rem;
    text-transform: none;
    padding: 0.97rem 3rem 0.97rem 1.9rem;
    border: none;
    font-weight: bold;

    &:hover, &:focus {
        color: #393939;
        background-color: #EBEAEA;
        text-decoration: none;
    }

    @include breakpoint(medium down) {
        text-align: center;
        width: 100%;
    }
}

.learning-lib-list-row {
    border-bottom: 1px solid #D8D8D8;
    margin-top: 64px;
    margin-bottom: 25px;
    min-height: 192px;

    .pdf-download a{
        font-family: 'Lato';
        font-size: 0.875rem;
        color: #000000;
        text-align: center;
        position: relative;
        font-weight: bold;
        position: relative;
        left: -4px;
        top: 9px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: underline;

        .fa {
            font-size: 1.625rem;
            margin-bottom: 5px;
            color: red;
        }
    }

    .title {
        font-family: 'Lato';
        font-size: 1rem;
        color: #0f809e;
        line-height: 1.6875rem;
        font-weight: bold;
    }

    .pub-date {
        display: block;
        font-family: 'Lato';
        font-size: 0.625rem;
        color: #6B6B6B;
        line-height: 1.6875rem;
        text-transform: uppercase;
        padding-top: 5px;
        padding-bottom: 4px;
    }

    p {
        margin-bottom: 1.65rem;
    }

    a {
        word-break: break-word;
    }
}

.no-cur-events {
    text-align: center;
    padding-top: 24px;
    margin-bottom: 32px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    td {
        padding: 5px;
        vertical-align: top;

        a {
            color: #388BA1;
            font-weight: bold;
            word-break: break-all;

            img {
                margin-right: 8.5px;
            }
        }
    }

    &.small-table {
        width: 350px;
    }

    &.two-col {
        td {
            width: 50%;
            padding-right: 12px;
            @include breakpoint(large) {
                padding-right: 24px;
            }
            font-size: 14px;

            a {
                word-break: break-word;
            }
        }
    }
}
