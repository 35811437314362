.subpage-header {
    min-height: 382px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include breakpoint(small only) {
        min-height: 300px;
    }
    
    .header-image-container {
        position: absolute;
        top: 197px;
        width: 100%;
        text-align: center;

        @media screen and (min-width: 994px) and (max-width: 1455px) {
            top: 13.5vw;
        }

        @media screen and (max-width: 993px) {
            top: 16%;
        }

        @include breakpoint(small only) {
            top: 40%;
        }

        .wrap {
            margin-left: auto;
            margin-right: auto;
            max-width: 1072px;

            .separator-line {
                width: 5.4375rem;
                margin: 2% auto 2% 0px;
                left: 9px;
                position: relative;
                height: 0.75rem;
            }

            .bg-fast-blue {
                background: #388BA1 !important;
            }
        }

        h1, .page-title {
            color: $white;
            font-family: 'PT-Serif';
            font-weight: 600;
            margin-bottom: 40px;
            margin-left: auto;
            margin-right: auto;
            max-width: 1072px;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
        }
    }
}

