// img {
//     width: 100%;
// }

.masonry {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: 14vh;
    grid-auto-flow: dense;
}

.masonry .mItem {
    display: block;
    overflow: hidden;
    writing-mode: horizontal-tb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
        margin: 0px;
        object-position: 50% top;
    }
}

.span2 {
    grid-row-end: span 2;
}

.span3 {
    grid-row-end: span 3;
}

.span4 {
    grid-row-end: span 4;
}

.span5 {
    grid-row-end: span 5;
}

.span6 {
    grid-row-end: span 6;
}

.height1 {
    grid-column-end: span 1;
}
.height2 {
    grid-column-end: span 2;
}
.height3 {
    grid-column-end: span 3;
}

@include breakpoint(medium only) {
    .masonry {
        grid-auto-rows: 7vh;
    }
}

@include breakpoint(small only) {
    .masonry {
        grid-auto-rows: 7vh;
    }
}