header {
    .header-main-wrapper {
        
        .grid-container {
            max-width: 1048px;
            padding: 0;
        }

        .navigation {
            display: flex;
            margin-left: 71.5px;
        }
        
        .cell.navigation {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @include breakpoint(small only) {
                justify-content: center;
            }
        }

        .top-bar {
            background-color: transparent;
            padding-left: 0px;
            padding-top: 0px;
            padding-bottom: 0px;

            ul.menu {
                background-color: transparent;
                float: right;
                z-index: 11;

                li {
                    a {
                        border: none;
                        text-decoration: none;
                        border-bottom: 2px solid transparent;
                        font-size: 0.8125rem;
                        font-weight: 800;
                        color: #000000;
                        letter-spacing: 0.7px;
                        position: relative;
                        text-transform: uppercase;

                        &:hover,
                        &:focus,
                        &:active {
                            color: #0f809e;
                        }

                        span {
                            color: inherit;
                            text-decoration: inherit;
                        }
                    }

                    &.is-dropdown-submenu-parent {
                        ul.sub-menu {
                            a:hover {
                                background-color: black;
                                color: $white;
                            }
                        }
                    }
                }

                &.parent-menu > li {
                    &:first-child a {
                        padding-left: 0;
                    }

                    &:last-child a {
                        padding-right: 0;
                    }
                }
            }
        }

        @include breakpoint(medium only) {
            .top-bar {
                .top-bar-right {
                    width: 400px;
                }
            }
        }

        @media (min-width: 1024) and (max-width: 1169) {
            .top-bar {
                ul.menu {
                    li {
                        margin: 0px 0.125rem;

                        a {
                            padding-left: 0.125rem;
                            padding-right: 0.125rem;
                        }
                    }
                }
            }
        }
    }

    .branding {
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(medium down) {
            float: left;
        }

        #logo {
            position: relative;
            margin: 0px;
            padding: 21.25px 0;

            @include breakpoint(large only) {
                padding-left: 10px;
            }

            @include breakpoint(medium down) {
                padding: 0 0 7.5px 10px;
            }

            a {
                display: block;
            }

            img {
                &.logo {
                    max-width: 109px;
                }
            }
        }

        @include breakpoint(small only) {
            #logo {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                img.logo {
                    max-width: 175px;
                }
            }
        }
    }

    .search-bar {
        display: flex;
        padding: 0 0.25rem;
        border-bottom: 1px solid $light-blue;

        label {
            flex: 0 0 80%;
        }

        button {
            flex: 0 0 20%;
            margin: 0px;
            padding: 0.5rem;
        }

        .search-field {
            background-color: transparent;
            border: none;
            box-shadow: none;
            border-radius: 0px;
            margin: 0px;
            padding: 0px;
        }
    }

    .mobile-top-bar {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin: 0px;
        padding: 0.5rem;

        .site-search {
            flex: 0 0 85%;
        }

        .mobile-toggle {
            flex: 0 0 15%;
            display: flex;
            justify-content: flex-end;

            .button {
                margin: 0px;
                padding: 0.5rem;
            }
        }
    }
}

#page-banner {
    position: relative;

    .photo-credit {
        color: white;
        font-size: 12px;
        font-weight: bold;
        line-height: 27px;
        padding: 0 10px;
        position: absolute;
        bottom: 1.5rem;
        color: #ffffff;
        right: 5%;

        @include breakpoint(small only) {
            text-align: left;
        }

        @include breakpoint(medium) {
            &::before {
                content: "";
                display: block;
                width: 60px;
                height: 1px;
                background: white;
                margin-bottom: 15px;
                top: -8px;
                right: 3px;
                position: absolute;
            }
        }
    }
}