.ups-grid {
    &.stats-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 3.75rem 1.625rem;
        padding: 0px;
        align-items: start;
        justify-content: center;

        .text-grid-single {
            display: flex;
            flex-direction: column;
            height: 100%;

            figure {
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                img {
                    object-fit: contain;
                }
            }

            h3 {
                margin-bottom: 0.5rem;
                line-height: 1.25em;
            }
        }
    }

    &.large-ups-grid {
        .text-grid-single {
            width: 100%;
        }
    }
}