.hero-container {
    &.component-container {
        padding: 0px !important;
        margin: 0px;
    }

    .hero-wrapper {
        padding: 0px;
        margin: 0px;

        @media (max-width: 90em) {
            max-height: 447px;
        }

        @media (min-width: 91em ) {
            min-height: 447px;
        }

        .item-wrapper {
            height: 100%;
            width: 100%;
            position: relative;

            .item {
                height: 100%;
                width: 100%;

                .item-inner-container {
                    height: 100%;
                    width: 100%;

                    @include breakpoint(medium only) {
                        width: 95%;
                    }

                    .item-inner-grid {
                        height: 100%;
                        width: 100%;

                        .item-inner-cell {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .item-block {
                                position: relative;

                                @include breakpoint(large) {
                                    top: -35px;
                                }
                            }
                        }
                    }

                    .item-block {
                        h2 {
                            text-transform: uppercase;

                            &.slide-heading {
                                font-size: 1.375rem;

                                @include breakpoint(small only) {
                                    font-size: 1.275rem;
                                }
                            }
                        }

                        .slide-copy {
                            font-size: 1.5rem;
                            line-height: 1.1;
                            font-family: $header-font-family;
                            margin-bottom: 1.8rem;

                            @include breakpoint(small only) {
                                font-size: 1.2rem;
                                margin-bottom: 0.8rem;
                            }

                            p {
                                line-height: 1.1;
                                font-size: 1.8rem;

                                @include breakpoint(small only) {
                                    font-size: 1.2rem;
                                }
                            }

                            strong {
                                font-weight: 600;
                            }

                            blockquote,
                            blockquote p {
                                font-size: 3rem;
                                line-height: 1.1;
                                margin: 0px;
                                padding: 0px;
                                border-left: none;

                                @include breakpoint(medium only) {
                                    font-size: 2rem;
                                }

                                @include breakpoint(small only) {
                                    font-size: 1.2rem;
                                }

                                p {
                                    line-height: 1.1;
                                }

                            }
                        }

                        .button-group {
                            .button {
                                margin: 0px 0.5rem;

                                &:first-child {
                                    margin-left: 0px;
                                }

                                &:last-child {
                                    margin-right: 0px;
                                }

                                @include breakpoint(small only) {
                                    padding: 0.5rem 0.5rem;
                                    font-size: 1rem;
                                }
                            }

                        }
                    }

                }

                img {
                    width: 100%;
                }

                &.dark {
                    background: #00133f;
                    background: linear-gradient(45deg, #00133f 1%, #00133f 36%, #035e93 87%, #035e93 100%);

                    .hero-content {
                        color: $white;

                        h2 {
                            color: $bright-orange;
                            letter-spacing: 2px;
                        }

                        .slide-copy {

                            blockquote,
                            blockquote p {
                                color: $white;
                            }
                        }
                    }

                    @include breakpoint(small only) {
                        .item-block {
                            background-color: rgba(0, 0, 0, 0.4);
                            padding: 1rem;
                        }
                    }

                }
            }
        }
    }

    &.hero-slider-container {
        .slider-wrapper {
            position: relative;
            height: 100%;

            .slick-buttons {
                display: none;
            }

            .home-slider {
                margin-bottom: 0px;

                .slick-arrow {
                    z-index: 110;
                    height: 35px;
                    width: 35px;

                    &:hover {
                        &::before {
                            color: $bright-green;
                        }
                    }

                    &::before {
                        font-family: "Font Awesome 6 Free";
                        font-weight: 900;
                        font-size: 2.25rem;
                        position: absolute;
                        top: 0px;
                    }

                    &.slick-prev {
                        left: 1rem;

                        &::before {
                            content: "\f137";
                            left: 0px;
                        }
                    }

                    &.slick-next {
                        right: 1rem;

                        &::before {
                            content: "\f138";
                            right: 0px;
                        }
                    }
                }

                .slick-dots {
                    bottom: 50px;
                    z-index: 110;

                    li {
                        button {
                            background: $white;
                            border: 1px solid $orange;
                            height: 15px;
                            width: 15px;

                            &::before {
                                display: none;
                            }
                        }

                        &.slick-active {
                            button {
                                background: $orange;
                                border: 1px solid $orange;
                            }
                        }
                    }
                }

                .slick-list {
                    z-index: 100;

                    .slick-track {
                        .slick-slide {
                            height: 42.7vw;
                            max-height: 615px;
                        }
                    }
                }
            }
        }
    }
}

.mini-gallery-wrap {
    width: 100%;

    .slick-gallery {
        position: relative;
        z-index: 100;

        .slick-arrow {
            z-index: 110;
            height: 35px;
            width: 35px;

            &:hover {
                &::before {
                    color: $bright-green;
                }
            }

            &::before {
                font-family: "Font Awesome 6 Free";
                font-weight: 900;
                font-size: 1.75rem;
                position: absolute;
                top: 0px;
            }

            &.slick-prev {
                left: 0.5rem;

                &::before {
                    content: "\f137";
                    left: 0px;
                }
            }

            &.slick-next {
                right: 0.5rem;

                &::before {
                    content: "\f138";
                    right: 0px;
                }
            }
        }

        .slick-list {
            z-index: 100;
        }


    }
}

.opacity-bg-dark-gray::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 15%, rgba(0, 0, 0, 0.7) 65%, rgba(0, 0, 0, 0.8) 131%);
}

.item-block-wrapper {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 40%;

    @include breakpoint(small only) {
        top: 2%;
    }

    @media screen and (min-width: 375px) and (max-width: 768px) {
        top: 15%;
    }

    @include breakpoint(medium only) {
        top: 30%;
    }

    .item-block {
        position: relative;
        text-align: center;

        .top {
            display: block;
            font-family: 'PT-Serif';
            font-weight: 600;
            font-size: 2rem;
            color: #FFFFFF;
            padding: 0px 0.5rem;
            margin-bottom: 0.7rem;

            @include breakpoint (small only) {
                font-size: 1rem;
            }
        }
    }

    h2.hero-text {
        font-family: 'Lato';
        font-size: 1rem;
        color: #FFFFFF;
        font-weight: 700;
        text-align: center;

        @include breakpoint (small only) {
            font-size: 14px;
        }
    }
}