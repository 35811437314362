@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-range-input;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700;800&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&display=swap');

@import 'global/mixins';
@import 'global/typography';
@import 'global/header';
@import 'global/navigation';
@import 'global/main';
@import 'global/footer';
@import 'global/sections';
@import 'global/sidebar';
@import 'global/subpage_banner';
@import 'global/tablesForms';
@import 'components/block_cards';
@import 'components/buttons';
@import 'components/form';
@import 'components/social';
@import 'components/mason-gallery';
@import 'components/slider';
@import 'components/icons';
@import 'components/articles';

// Distro Components
@import 'distro/components';
@import 'distro/alt-grids.scss';

// Custom styles for timeline
// @import 'distro/timeline-base';

// Custom components sheet
@import 'distro/custom-components';

// Slick Carousel
// @import './node_modules/slick-carousel/slick/slick.scss';
// @import './node_modules/slick-carousel/slick/slick-theme.scss';
// @import 'components/coursel';

// Skip to main content
#ada-skiptomain {
    background: $primary-color;
    color: $white;
    height: 30px;
    left: 50%;
    padding: 8px;
    position: absolute;
    transform: translateY(-100%);
    transition: transform 0.3s;

    &:focus {
        transform: translateY(0%);
    }
}